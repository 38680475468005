/**
 * @generated SignedSource<<ceaf2764e31762fc955612fef3e92d3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationMembersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationAdmins_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationPlayers_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationMembersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "role",
                "value": "admin"
              }
            ],
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "superAdmin",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "members(role:\"admin\")"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e288af443ea30fe40269040f0d4678c1",
    "id": null,
    "metadata": {},
    "name": "OrganisationMembersQuery",
    "operationKind": "query",
    "text": "query OrganisationMembersQuery(\n  $id: ID!\n) {\n  organisation(id: $id) {\n    ...OrganisationAdmins_organisation\n    ...OrganisationPlayers_organisation\n    id\n  }\n}\n\nfragment InviteAdminModal_organisation on Organisation {\n  id\n  databaseId\n}\n\nfragment OrganisationAdmin_member on Member {\n  id\n  status\n  user {\n    email\n    name\n    superAdmin\n    id\n  }\n}\n\nfragment OrganisationAdmins_organisation on Organisation {\n  members(role: \"admin\") {\n    id\n    ...OrganisationAdmin_member\n  }\n  ...InviteAdminModal_organisation\n}\n\nfragment OrganisationPlayers_organisation on Organisation {\n  players {\n    id\n    name\n    user {\n      email\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "8e215f7076b3dce04e5aef8615e5eb5f";

module.exports = node;
