import { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const collapsedHeight = 0;

const Container = styled.div`
  ${({ on, state }) => !on && state !== 'entered' && 'height: 0;'}
  visibility: ${({ state }) => (state === 'exited' ? 'hidden' : 'visible')};
  overflow: ${({ state }) => (state === 'entered' ? 'visible' : 'hidden')};
  ${({ duration }) => `transition: min-height ease ${duration}ms;`}
`;

const Collapse = ({ children, duration, ...props }) => {
  const node = useRef();
  const inner = useRef();

  const handleEntered = () => (node.current.style.height = 'auto');
  const collapse = () => {
    node.current.style.minHeight = collapsedHeight;
    node.current.style.height = collapsedHeight;
  };

  const expand = () => {
    const wrapperHeight = inner.current ? inner.current.clientHeight : 0;
    node.current.style.minHeight = `${wrapperHeight}px`;
  };

  return (
    <Transition
      {...props}
      onEnter={collapse}
      onEntered={handleEntered}
      onEntering={expand}
      onExit={expand}
      onExited={collapse}
      nodeRef={node}
      timeout={{
        appear: duration,
        enter: duration,
        exit: 0,
      }}
    >
      {(state) => (
        <Container duration={duration} ref={node} state={state} on={props.on}>
          <div ref={inner}>{children}</div>
        </Container>
      )}
    </Transition>
  );
};
Collapse.defaultProps = {
  duration: 200,
};

Collapse.propTypes = {
  children: PropTypes.node,
};

export default Collapse;
