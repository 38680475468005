import styled from 'styled-components';
import PropTypes from 'prop-types';

const Ellipse = styled.div`
  border-radius: 50%;
  position: absolute;
  ${({ status }) => {
    switch (status) {
      case 'active':
        return 'left: 15px; background: var(--success-color);';
      case 'inactive':
        return 'left: 3px; background: #293C5B;';
      case 'disabled':
        return 'left: 3px; background: #C1CDE2; cursor: disabled;';
      default:
    }
  }}
  ${({ duration }) => `transition: left ${duration}ms linear;`}
  top: 2px;
  width: 0.75rem;
  height: 0.75rem;
`;

const Rectangle = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 100px;
  cursor: pointer;
  width: 2rem;
  height: 1.125rem;

  ${({ right }) => right && 'margin-left: auto;'}
  ${({ disabled, status }) =>
    status === 'disabled' || disabled
      ? 'border: 1px solid #96A9C9; background: #F8FBFF; cursor: not-allowed;'
      : 'border: 1px solid #293c5b; cursor: pointer;'}
`;

const duration = 300;

const Switch = ({ disabled, right, status, onClick }) => {
  const attrs =
    status !== 'disabled' && onClick
      ? {
          onClick,
          onKeyDown: (e) => e.code === 'Enter' && onClick(),
          role: 'button',
          tabIndex: '0',
        }
      : {};

  return (
    <Rectangle disabled={disabled} right={right} status={status} {...attrs}>
      <Ellipse status={status} duration={duration} />
    </Rectangle>
  );
};

Switch.defaultProps = {
  disabled: false,
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string.isRequired,
};

export default Switch;
