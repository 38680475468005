/**
 * @generated SignedSource<<50d932afefc3bc683d79ae812731262d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultCountryCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScoreCardPreview_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationDetailsForm_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "640e8a2d2ce020c7256f3ef32ab167cf",
    "id": null,
    "metadata": {},
    "name": "OrganisationDetailsQuery",
    "operationKind": "query",
    "text": "query OrganisationDetailsQuery(\n  $id: ID!\n) {\n  organisation(id: $id) {\n    defaultCountryCode\n    name\n    ...ScoreCardPreview_organisation\n    ...OrganisationDetailsForm_organisation\n    id\n  }\n}\n\nfragment OrganisationDetailsForm_organisation on Organisation {\n  color\n  defaultCountryCode\n  id\n  name\n  ...ScoreCardPreview_organisation\n}\n\nfragment ScoreCardPreview_organisation on Organisation {\n  color\n}\n"
  }
};
})();

node.hash = "ed4996e9f62a64427456293f05020efb";

module.exports = node;
