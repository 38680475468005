/**
 * @generated SignedSource<<c80c702e3e803040c8e0e3ea9375f4a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nationality",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scoreTemplate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PairsPlusLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PairsPlusLeaderboardTable_tournament"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PairsPlusLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeRound",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPairsPlus",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Pair",
            "kind": "LinkedField",
            "name": "rankedPairs",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalScore",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FlightScore",
                "kind": "LinkedField",
                "name": "activeFlightScores",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finishedHoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "playerId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "score",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalStrokes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flight",
                    "kind": "LinkedField",
                    "name": "flight",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startingHole",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scores",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "playerA",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "playerB",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "activeTournamentRound",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shotgun",
                "storageKey": null
              },
              (v4/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "512ca9c1ac2a887d6ae091a53f16f331",
    "id": null,
    "metadata": {},
    "name": "PairsPlusLeaderboardQuery",
    "operationKind": "query",
    "text": "query PairsPlusLeaderboardQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...PairsPlusLeaderboardTable_tournament\n    id\n  }\n}\n\nfragment FinishedHoles_tournament on Tournament {\n  status\n  activeTournamentRound {\n    shotgun\n    id\n  }\n}\n\nfragment PairsPlusCollapse_pair on Pair {\n  activeFlightScores {\n    scores\n    id\n  }\n}\n\nfragment PairsPlusCollapse_tournament on Tournament {\n  activeTournamentRound {\n    scoreTemplate\n    id\n  }\n  organisation {\n    color\n    id\n  }\n  tournamentRounds {\n    scoreTemplate\n    id\n  }\n}\n\nfragment PairsPlusLeaderboardTableEntry_rankedPair on Pair {\n  position\n  status\n  totalScore\n  activeFlightScores {\n    finishedHoles\n    playerId\n    score\n    totalStrokes\n    flight {\n      startDate\n      startingHole\n      status\n      id\n    }\n    id\n  }\n  playerA {\n    databaseId\n    name\n    nationality\n    id\n  }\n  playerB {\n    name\n    nationality\n    id\n  }\n  ...PairsPlusCollapse_pair\n}\n\nfragment PairsPlusLeaderboardTableEntry_tournament on Tournament {\n  ...FinishedHoles_tournament\n  ...PairsPlusCollapse_tournament\n  ...Position_tournament\n  ...ScoreTotal_tournament\n}\n\nfragment PairsPlusLeaderboardTable_tournament on Tournament {\n  activeRound\n  databaseId\n  hasPairsPlus\n  status\n  rankedPairs {\n    id\n    position\n    ...PairsPlusLeaderboardTableEntry_rankedPair\n  }\n  ...PairsPlusLeaderboardTableEntry_tournament\n  id\n}\n\nfragment Position_tournament on Tournament {\n  activeRound\n}\n\nfragment ScoreTotal_tournament on Tournament {\n  activeRound\n  organisation {\n    color\n    id\n  }\n}\n"
  }
};
})();

node.hash = "9843335ceb197e9588207b1be2111882";

module.exports = node;
