import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from '../Dropdown';
import Button from './Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Group = styled.div`
  display: flex;

  button {
    color: var(--black);
    background-color: var(--light-grey);
    padding 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;

     ${({ isActive }) => isActive && 'background-color: var(--primary-color); color: var(--white);'}
  }

  & > a,
  & > button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  & > button:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export default function ButtonGroup(props) {
  return <Group isActive={props.isActive}>{props.children}</Group>;
}

function GroupDropdown(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const onDropdownClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const onClose = () => setAnchorEl(null);

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [window.location.pathname, window.location.search]);

  return (
    <>
      <Button onClick={onDropdownClick} type="button">
        <FontAwesomeIcon icon={faCaretDown} />
      </Button>
      <Dropdown anchorEl={anchorEl} onClose={onClose} right>
        {props.children}
      </Dropdown>
    </>
  );
}

ButtonGroup.Dropdown = GroupDropdown;
